@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("~leaflet/dist/leaflet.css");

.leaflet-container {
  height: 100vh;
  width: 100%;
  margin-left: 2rem;
  z-index: 1;
  /* Lower z-index compared to the header */
}

.about-us {
  width: 40vw;
  height: 95%;
}

.promo-villa {
  height: 80px;
  width: 100px;
}

* {
  /* font-family: 'Barlow', sans-serif !important; */
  font-family: Quicksand, Arial sans-serif !important;
  /* font-size: 97%; */
  scroll-behavior: smooth;
  box-sizing: border-box;
  word-break: break-word;
}

html,
body {
  width: 100%;
  /* overflow-x: hidden; */
}

.bakcground-section-color {
  background-color: #f5f7fa;
}

/* .background-image {
  background-image: url("./assets/bg.webp");
} */

body {
  color: #4c4c4d;
  line-height: 150%;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.header {
  background-color: transparent;

  /* width: 100vw; */
}

.header-no-home {
  /* width: 100vw; */
  background-color: #999591;
}

.menu-flags {
  background-color: #fff;
  padding: 0 !important;
  border-radius: 0.25rem;
  min-width: 150px;
}

.menu-flags-mobile {
  background-color: #fff;
  padding: 0 !important;
  border-radius: 0.25rem;
}

.menu-flags ul {
  color: #4c4c4d;
}

.menu-flags li {
  transition: color 0.3s ease-out;
}

.menu-flags li:hover {
  color: #daa520;
}

.menu-flags-mobile ul {
  color: #4c4c4d;
}

.menu-flags-mobile li {
  transition: color 0.3s ease-out;
}

.menu-flags-mobile li:hover {
  color: #daa520;
}

.hover-li:hover {
  display: flex;
  flex-direction: column;
}

.nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  cursor: pointer;
}

.nav-item-mobile {
  position: relative;

  cursor: pointer;
}

.nav-item:hover .sub-ul {
  opacity: 1;
  visibility: visible;
}

.nav-item .fa-chevron-down {
  font-size: 14px;
}

.sub-ul li {
  list-style: none;
  font-weight: 500;
  padding: 12px 20px;
  color: #4c4c4d;
  font-size: 17px;
  transition: color 0.3s ease-out;
  /* border-bottom: 1px solid #ccc */
}

.sub-ul li:hover {
  /* background: #f5f5f5; */
  color: #daa520;
  border-radius: 5px;
  /* list-style: inside; */
}

.header .sub-ul {
  background: #f5f7fa;
  position: absolute;
  padding: 0;
  width: 220px;
  opacity: 0;
  /* color: black; */
  border-radius: 5px;
  cursor: pointer;
  top: 57px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  z-index: 1000;
}

.header-no-home .sub-ul {
  background: #f5f7fa;
  position: absolute;
  padding: 0;
  width: 220px;
  opacity: 0;
  /* color: black; */
  border-radius: 5px;
  cursor: pointer;
  top: 57px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  z-index: 1000;
}

.sub-ul a {
  color: inherit;
  text-decoration: none;
}

.search-no-home {
  min-height: 250px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 44, 133, 0.2), transparent),
    url("./assets/backgrounds/bg7.jpg");
  /* Linear gradient applied on top of the image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-area {
  /* background-color: white;
  padding: 5px 20px;
  box-shadow: 0 10px 12px rgba(77, 60, 160, 0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(248 250 252 / 67%);
  padding: 20px;
  gap: 10px;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}

.search-area-child {
  flex-grow: 1;
  min-width: 200px;
}

.banner {
  /* min-height: 580px; */
  color: White;
  background: #eff6ff no-repeat;
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}

.inp-search {
  /* border: 0;
  outline: 0 none; */
  /* padding: 15px; */
  border-radius: 0.2rem;
  border: 0;
  outline: 0 none;
  padding: 16px 15px 3px;
  height: 42px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.inp-select {
  border: 0;
  outline: 0 none;
  width: 100%;
  padding: 15px;
}

.search-separator {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  width: 0.5rem;
  height: 3rem !important;
  color: #808080;
}

.find-search {
  position: absolute;
  background: white;
  top: 224px;
  width: 100%;
  border-radius: 8px;
  color: black;
  padding: 20px;
  text-align: start;
  overflow-y: scroll;
}

.find-search a {
  display: block;
}

.bg-custom {
  position: relative;
  /* background: rgb(29 34 43 / 40%); */
  width: 100%;
  /* height: 100%; */
  min-height: 550px;
  /* Use min-height instead of height */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* word-break: break-all; */
  background: linear-gradient(rgba(0, 44, 133, 0.3) 0%, transparent 100%);
  /* overflow: hidden; */
}

.btn-search {
  background: #daa520;
  /* Softer golden color in RGB */
  /* border: 0; */
  border-width: 2px;
  color: #4c4c4d;
  border-radius: 5px;
  border-style: solid;
  border-color: #daa520;
  padding: 12px 25px;
  min-width: 130px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-search:hover {
  background-color: white;
  color: #daa520;
  transition: background-color 0.3s ease, color 0.3s ease,
    text-decoration 0.3s ease;
}

.btn-cancel-search {
  background: #9e4242;
  border-radius: 0.2rem;
  border: 1px solid #9e4242;
  color: white;
  padding: 12px 25px;
  min-width: 130px;
}

.btn-cancel-search:hover {
  background: #9e4242;
  color: white;
}

.btn-nonsearching {
  margin-left: 1rem;
  background: #9e4242;
  /* Softer golden color in RGB */
  border-radius: 0.2rem;
  border: 1px solid #9e4242;
  /* Border color */

  color: white;
  padding: 12px 25px;
  min-width: 130px;
}

.banner-area {
  position: relative;
}

.banner-title {
  color: white;
  font-size: 50px;
  text-wrap: nowrap;
  /*color: #1a2340;*/
}

.title-area {
  margin-bottom: 40px;
}

.title {
  font-size: 40px;
  margin: 0;
  /* white-space: nowrap; */
  /* color: #3a4867de; */
  color: #4c4c4d;
  font-weight: bold;
}

.title-description {
  font-size: 15px;
  margin: 0;
}

.item-image {
  overflow: hidden;
}

.item-image img {
  min-height: 240px;
  width: 100%;
  max-height: 240px;
  object-fit: cover;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.item {
  margin-bottom: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

.item-background {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.nav-link {
  font-size: 16px;
  color: #fff !important;
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  /* Added text-decoration to the transition */
  list-style: none;
  white-space: nowrap;
  padding: 5px 0px;
  margin: 0px 7.5px;
  pointer-events: auto;
  text-decoration: none !important;
  position: relative;
  /* Added position relative for ::before pseudo-element */
}

.nav-link:hover {
  color: #daa520 !important;
  /* Change this to the color you want on hover */
  text-decoration: underline;
  /* Underline on hover */
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  /* Adjust the thickness of the underline */
  bottom: 0;
  left: 0;
  background-color: #daa520;
  /* Change this to the color you want for the underline */
  transform: scaleX(0);
  /* Initially set to zero width, will expand on hover */
  transform-origin: bottom right;
  transition: transform 0.4s ease;
  /* Add transition for the transform */
}

.nav-link:hover::before {
  transform: scaleX(1);
  /* Expand the underline on hover */
}

.special-link {
  color: white !important;
  text-decoration: none;
}

.navbar-brand {
  padding: 0;
  font-size: 30px;
  font-weight: bold;
}

.item-description {
  padding: 15px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.item-description div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-all-re .item-title {
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  color: #4c4c4d;
}

.section-all-re .item-price {
  font-weight: bold;
  min-width: 100px;
  font-size: 25px;
  /* color: #c29021; */
  color: #4c4c4d;
}

.section-all-re .item-location {
  font-weight: bold;
  margin-bottom: 0.6rem;
  color: #b3c0c0;
}

.item .fas {
  color: #b3c0c0;
}

.item-icon {
  color: black;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.btn-detail {
  background-color: #daa520;
  color: white;
}

.footer {
  padding-top: 4rem;

  background: #27282e;
  color: white;
}

.footer .fa-instagram,
.footer .fa-facebook,
.footer .fa-twitter {
  color: #ffffff8a !important;
  font-size: 22px;
}

.footer-title {
  font-size: 21px;
  font-weight: bold;
}

.footer-ul {
  margin: 0;
  /* padding: 0; */
}

.promovilla {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.promovilla-contact-details {
  height: 100px;
  /* width: 100%; */
  /* max-height: 135px; */
  margin-right: 0.2rem;
}

.footer-ul li a {
  list-style: none;
  margin-left: 0;
  font-size: 16px !important;
  text-decoration: none;
  margin-bottom: 10px;
  color: #ffffff8a;
}

.footer-ul li a:hover {
  color: #daa520;
}

.footer-other-text {
  font-size: 15px;
  color: #ffffff8a;
}

.fa-home {
  font-size: 40px;
}

.footer .fa-facebook,
.footer .fa-twitter,
.footer .fa-instagram-square {
  font-size: 20px;
}

.inp-footer {
  outline: 0;
  border: 0;
}

.footer-social {
  display: flex;
}

.footer-social-item {
  width: 45px;
  height: 45px;
  background: #393a42;
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 50%;
}

.footer-info-item {
  width: 25px;
}

.subscribe-area {
  background-color: white;
  padding: 5px 10px;
  box-shadow: 0 10px 12px rgba(77, 60, 160, 0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.btn-subscribe {
  background: #009688;
  border-radius: 0.2rem;
  border: 0;
  color: white;
  padding: 12px 25px;
}

section {
  /*padding-top: 80px;
  padding-bottom: 110px;*/
}

.best-estate-item {
  box-shadow: 0 0 10px 0 rgb(62 28 131 / 10%);
  margin: 10px;
  border-radius: 10px;
  background: #f7f9fc;
  overflow: hidden;
}

.best-estate-item h4 {
  margin: 0;
  font-size: 16px;
  color: #364465;
  font-weight: bold;
}

.best-estate-content a {
  color: inherit;
  text-decoration: none;
}

.best-estate-item .best-estate-img {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  transition: 0.5s linear;
  cursor: pointer;
}

.best-estate-item .best-estate-img:hover {
  transform: scale(1.2);
}

.best-estate-item span {
  font-size: 14px;
  color: #364465;
}

.best-estate-content {
  padding: 15px;
  border-bottom: 1px solid #ececec;
  background: white;
}

.best-estate-features {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.best-estate-feature {
  margin-right: 20px;
}

.best-estate-feature span {
  margin-left: 5px;
}

.best-estate-features .best-estate-price {
  font-size: 22px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.section-best-estate {
  background: #82858c1a;
}

.best-estate-state {
  color: #fff;
  position: absolute;
  top: 9px;
  left: -30px;
  transform: rotate(-45deg);
  background: #4c4c4d;
  padding: 5px 35px;
  font-size: 13px;
}

.best-estate-state-details {
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  /* transform: rotate(-45deg); */
  background: #4c4c4d;
  padding: 5px 35px;
  font-size: 13px;
  z-index: 9999999;
}

.best-estate-img-area {
  position: relative;
  overflow: hidden;
}

.bg-red {
  background: #4c4c4d;
}

.bg-vendu {
  background: #dc35358f;
}

.bg-loue {
  background: #0d6efd70;
}

.bg-green {
  background: #999591;
}

.sbs-area-inp {
  padding: 10px;
  border: 1px solid gainsboro;
  outline: none;
  border-radius: 5px;
}

.section-subscribe {
  background: #4b5698;
}

.section-subscribe .title {
  color: white;
}

.sbs-description {
  color: #e6e6e6;
}

.team {
  border: 1px solid #eeeeee;
}

.team .team-name {
  text-align: center;
  color: #3a4867de;
  font-size: 24px;
  font-weight: bold;
}

.team .team-position {
  text-align: center;
  color: #53607b;
  margin-top: 10px;
  margin-bottom: 20px;
}

.team .team-img {
  text-align: center;
}

.team .team-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.team .team-info {
  padding: 30px 5px;
  transition: 0.3s;
}

.team .social-links {
  display: flex;
  justify-content: center;
}

.team .social-item {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #ffffffab;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: #525659;
  color: white;
  transition: 0.3s;
}

.team:hover .team-info {
  background: #27282e;
  cursor: pointer;
}

.team:hover .team-name {
  color: white;
}

.team:hover .team-position {
  color: #ffffffab;
}

.team:hover .social-item {
  background: white;
  color: #525659;
}

.section-references {
  background: #f2f3f3;
}

.section-references img {
  object-fit: cover;
  height: 100px !important;
}

.section-references .slick-slide div {
  margin: 10px;
}

.page-top {
  background: #daa520;
  padding: 50px;
  color: white;
}

.page-content {
  padding-top: 5em;
  padding-bottom: 5em;
  background-color: #f5f7fa;
}

.page-top .page-title {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.page-top .page-description {
  font-size: 22px;
  text-align: center;
}

/* Contact Start */
.contact label {
  display: block;
  color: #27282e;
  font-size: 14px;
  font-weight: bold;
}

.contact .inp-contact,
.ta-contact {
  border: 1px solid #e4e4e4;
  background: whitesmoke;
  width: 100%;
  outline: none;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.ta-contact {
  resize: none;
}

.contact .btn-contact {
  border: 0;
  padding: 12px 50px;
  background: #daa520;
  color: white;
}

.contact .contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background: whitesmoke;
}

.contact .contact-item h5:hover {
  color: #daa520;
}

.contact .contact-item h6:hover {
  color: #daa520;
}

.contact .contact-item h6 a {
  text-decoration: none;
  color: inherit;
}

.contact .contact-item h6 a:hover {
  color: #daa520;
}

.contact .fas {
  font-size: 25px;
}

/* Contact End*/

.section-references,
.section-teams,
.section-best-estate,
.section-all-re {
  padding-top: 20px;
  padding-bottom: 40px;
}

/* Blog Start */
.blog {
  background: #82858c1a;
}

.blog-item {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 20px;
}

.blog-content {
  padding: 15px;
  background: #fff;
}

.blog-title {
  font-size: 16px;
  font-weight: bold;
  color: #364465;
  margin: 0;
}

.blog-title a {
  color: inherit;
  text-decoration: none;
}

.blog-text {
  color: #797979e0;
  font-size: 13px;
}

.blog-info {
  display: flex;
  margin: 9px 0px;
}

.blog-info-item {
  font-size: 13px;
  color: #757575;
}

.blog-info-item:nth-child(1) {
  margin-right: 15px;
}

.blog .fa-calendar-alt,
.blog .fa-comments {
  margin-right: 5px;
}

/* Blog End */

/* About Start */
.about {
  background: #f5f7fa;
}

.about img {
  border-radius: 10px;
}

.about-text {
  color: #797979e0;
  font-size: 17px;
  margin: 15px 0;
}

.about .fa-long-arrow-alt-right {
  color: crimson;
  font-size: 18px;
  margin-right: 5px;
}

.about-features {
  margin: 10px 0px;
}

.about .about-feature {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #364465;
  display: flex;
  align-items: center;
}

/* .about-item {
  padding: 30px;
} */

/* About End */

.contact-us {
  display: flex;
}

/* Media Start */
@media (max-width: 500px) {
  .search-area {
    justify-content: center;
    flex-direction: column;
    align-items: normal;
  }

  .find-search {
    top: 350px !important;
  }
}

@media (max-width: 990px) {
  .promovilla {
    width: 80px;
    height: auto;
  }

  .sbs-area-inp {
    display: block;
    margin-bottom: 10px;
  }

  .fd-price {
    font-size: 30px !important;
  }

  .title {
    font-size: 20px;
  }

  .about-us {
    width: 100%;
  }

  .leaflet-container {
    margin: 0;
  }

  .contact-us {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-item {
    margin-bottom: 0.3rem;
  }

  .search-area {
    min-width: 90vw;
  }
}

@media (max-width: 1300px) {
  .banner-title {
    text-wrap: wrap;
  }
}

/* Media End */

.blog-detail {
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.blog-detail-category {
  font-size: 12px;
  color: #253156;
  font-weight: bold;
  margin-top: 15px;
  display: block;
}

.blog-detail-title {
  font-weight: bold;
}

.blog-detail-date {
  font-size: 12px;
  color: #797979e0;
}

.blog-detail-content {
  margin-top: 15px;
}

.blog-detail-alttitle {
  font-weight: bold;
  font-size: 20px;
}

.widget {
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 25px;
}

.widget:first-child {
  margin-top: 0px;
}

.widget-title {
  font-weight: bold;
  color: #364465;
}

.widget-search-inp {
  width: 100%;
  background: #eeeeee;
  border: 1px solid #25315647;
  outline: none;
  padding: 8px 16px;
  border-radius: 5px;
}

.widget-search-result {
  background: #eeeeee;
  padding: 15px;
  border: 1px solid #b6bac4;
  border-radius: 5px;
  margin-top: 9px;
}

.widget-search-result p {
  margin: 0;
}

.right-sidebar .category-ul {
  margin: 0;
  padding: 0;
}

.right-sidebar .category-ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.flat-detail {
  background-color: #f5f7fa;
}

.flat-detail-content {
  background: white;
}

.flat-detail-title {
  font-weight: bold;
}

.fd-address {
  margin: 0;
}

.fd-address i {
  margin-right: 5px;
}

.fd-top {
  margin-top: 20px;
  display: flex;
  padding: 35px;
  justify-content: space-between;
}

.fd-price {
  font-weight: bold;
  font-size: 40px;
}

.fd-item,
.fd-sidebar-item {
  background: white;
  padding: 35px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.fd-item p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
}
.fd-item h4,
.fd-sidebar-item h4 {
  font-weight: bold;
  margin-bottom: 15px;
}

.fd-features .fa-check {
  color: #daa520;
}

.fd-features span {
  margin-left: 5px;
  font-weight: 500;
}

.fd-property-detail .col-lg-4 span {
  font-weight: 500;
}

.recently-item:nth-child(2),
.recently-item:nth-child(3) {
  margin-bottom: 20px;
}

.recently-item img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}

.recently-item span {
  margin-left: 10px;
  font-weight: 500;
}

.fd-sidebar-item ul {
  padding-left: 20px;
}

.image-gallery-image {
  object-fit: cover;
}

/* Add this CSS in your component or a separate CSS file */

.mobile-header {
  background-color: #f8f9fa;
  padding: 15px;
}

.menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon {
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}

.close-menu {
  cursor: pointer;
  font-size: 40px;
  color: #fff;
  margin-top: 1rem;
  background-color: #daa520;
  border-radius: 5px;
  margin-right: 1.5rem;
}

.close-menu-mobile {
  cursor: pointer;
  font-size: 25px;
  color: #fff;
  background-color: #daa520;
  border-radius: 5px;
  margin-right: 1.5rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  display: flex;
  /* align-items: center; */

  z-index: 9999;
}

.order {
  background-color: #4c4c4d;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* align-items: flex-end; */
}

.links {
  list-style: none;
  /* position: absolute; */
  /* padding-top: 1rem; */
  /* padding-left: 8rem; */
  /* padding-right: 8rem; */
  flex: 1;
  width: 320px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  overflow-y: auto;
  /* justify-content: space-evenly; */
}

.links li {
  margin-bottom: 10px;
  list-style-type: square;
  color: #daa520;
}

.sub-menu {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  padding: 0px 20px 0px 20px;
}

.parent-sub-menu {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.parent-sub-menu-icon {
  color: #fff;
  margin-right: 1.5rem;
  font-size: 30px;
}

.append-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.links a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f8f8f8;
  font-weight: 500;
  font-size: 19.2px;
}

.links a:hover {
  color: #daa520;
}

.links .icon {
  margin-right: 10px;
}

.links .category {
  display: flex;
  align-items: center;
}

.links .sub-ul {
  margin-left: 15px;
  padding-left: 10px;
  list-style: none;
  display: none;
}

.links .sub-ul li {
  margin-bottom: 5px;
}

.links .category:hover .sub-ul {
  display: block;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  /* margin-bottom: 2.5rem; */
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
  padding: 8px 15px;
  /* background-color: #DAA520; */
  background-color: #4c4c4d;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  text-decoration: #fff;
  color: #fff;
}

.pagination .page-num:hover {
  /* background-color: #253156; */
}

.pagination .active {
  background-color: #253156 !important;
  color: #fff;

  /* Change the text color */
}

.active-link {
  background-color: #daa520 !important;
}

.loader {
  text-align: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.search {
  display: flex;
  flex-direction: column;
}

.search input {
  width: 500px;
}

.button-search {
  position: relative;
}

.form-contact {
  margin-top: 2rem;
}

.form-contact button {
  background-color: #daa520;
  width: 100%;
  height: 50px;
  border-width: 2px;
  border-color: #daa520;
  /* color: #4c4c4d; */
  border-radius: 5px;
  border-style: solid;
  /* border: none; */
  /* border-radius: 0.3rem; */
}

.form-contact button:hover {
  background-color: white;
  color: #daa520;
  transition: background-color 0.3s ease, color 0.3s ease,
    text-decoration 0.3s ease;
}

.img-highlights {
  margin-right: 0.2rem;
  height: 22px;
  width: 22px;
}

.property-details {
  display: flex;
  gap: 1rem;
}

.details-architecture {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.details-container {
  display: flex;
}

.image-container {
  display: flex;
  justify-content: center;
  background-color: #ececec;
  width: 50px;
  height: 60px;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
}

.image-container img {
  width: 30px;
  height: 30px;
  align-self: center;
}

.flex-container {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.flex-option {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.option-img {
  width: 30px;
  height: 30px;
}

.option-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.2rem;
}

.option-img-container:hover {
  background-color: #daa520;
}

.checked {
  background-color: #daa520;
  border-radius: 0.2rem;
}

.option-text {
  margin-left: 8px;
}

.property-image {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  transition: 0.5s linear;
  cursor: pointer;
}

.property-image:hover {
  transform: scale(1.1);
}

.filter-icon {
  cursor: pointer;
  background-color: #daa520;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  color: #4c4c4d;
  border-color: #daa520;
  height: 42px;
  width: 38px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-icon:hover {
  background-color: white;
  color: #daa520;
  transition: background-color 0.3s ease, color 0.3s ease,
    text-decoration 0.3s ease;
}

.filter-icon img {
  width: 40px;
  height: 40px;
}

.filter-options {
  padding-top: 8rem;
  background-color: white;
  padding: 20px 20px;
  box-shadow: 0 10px 12px rgba(77, 60, 160, 0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  /* bottom: 0; */
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 0.5rem;
}

.input-order {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  gap: 1rem;
}

.inp-search-model {
  border: 1px solid rgb(211, 211, 210);
  border-radius: 0.4rem;
  width: 100% !important;
  outline: 0 none;
  width: 100%;
  padding: 15px;
}

.type-property {
  font-size: 13px;
  /* Reduced font size for subtlety */
  position: absolute;
  /* Keeps the label in a specified position */
  bottom: 10px;
  /* Slightly more space from the bottom edge */
  left: 10px;
  /* Slightly more space from the left edge */
  color: #daa520;
  /* Golden color for the text, stands out but remains elegant */
  background-color: #4c4c4d;
  /* Dark gray background for contrast and readability */
  padding: 0.25rem 0.5rem;
  /* Just enough padding to maintain legibility */
  border-radius: 3px;
  /* Softly rounded corners for a modern look */
  font-weight: 500;
  /* Medium weight for the text */
  letter-spacing: normal;
  /* Default letter spacing */
  text-transform: capitalize;
  /* Only the first letter of each word is capitalized */
}

.type-property:hover {
  cursor: initial;
}

.reference-property {
  font-size: 14px;
  /* Slightly smaller font for subtlety */
  position: absolute;
  /* Keeps the reference within the card */
  bottom: 10px;
  /* More balanced spacing from the bottom */
  right: 10px;
  /* Aligns the text slightly more inward */
  color: #435a6f;
  /* A softer, deep blue hue for better readability */
  background-color: rgba(255, 255, 255, 0.8);
  /* A translucent white background */
  padding: 0.3rem 0.5rem;
  /* Comfortable padding around the text */
  border: 1px solid #d1d9e6;
  /* Adds a subtle border */
  border-radius: 4px;
  /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  text-align: center;
  /* Centers text within the element */
  text-transform: uppercase;
  /* Capitalizes the reference for distinction */
  letter-spacing: 0.05em;
  /* A little spacing for legibility */
  font-weight: 600;
  /* Slightly bolder font to stand out */
}

.reference-property:hover {
  cursor: initial;
}

.inp-select option {
  font-size: 20px;
}

.footer-logo {
  height: 100px;
  width: 100px;
}

.contact-d {
  text-decoration: none;
  color: inherit;
}

.contact-d:hover {
  color: #daa520;
}

.typewriter h5 {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
  animation-iteration-count: infinite;
  /* Set a fixed width */
}

/* The typing effect */

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */

@keyframes blink-caret {
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.contact-navbar {
  display: flex;
}

.search-area-child {
  position: relative;
}

.inp-search {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.inp-search:focus {
  border-color: #daa520;
  /* Change this to the desired border color when focused */
  outline: none;
}

.inp-search::placeholder {
  color: #999;
  transition: transform 0.3s ease-out, font-size 0.3s ease-out;
}

.search-area-child.focused .inp-search::placeholder {
  transform: translateY(-15px);
  font-size: 12px;
}

/* Additional styling as needed */

.form-search {
  display: flex;
  justify-content: center;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
  color: #4c4c4d;
}

.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 28px;
  height: 28px;
  box-shadow: rgba(0, 44, 133, 0.1) 0px 5px 10px 0px;
  border-radius: 5px;
  background: #fff;
}

.styled-checkbox:hover + label:before {
  /* background: #4c4c4d; */
}

.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
  /* background: #DAA520; */
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox + label:after {
  content: "✔";
  position: absolute;
  left: 5px;
  color: #daa520;
  font-size: 22px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

:dir(rtl) .styled-checkbox + label:after {
  right: 13px;
  /* left: 20px; */
  /* right: auto; */
}

.styled-checkbox:checked + label:after {
  opacity: 1;
}

.unstyled {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 1rem;
  list-style-type: none;
  flex-wrap: wrap;
}

.unstyled li {
  margin: 20px 0;
}

.container-mobile {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  padding-left: 1rem;
}

.mobile-language-close {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 1rem;
  gap: 2rem;
  margin-left: 0.5rem;
}

.select-language {
  margin: 0 !important;
  padding: 0 !important;
}

.select-currency {
  margin: 0 !important;
  padding: 0 !important;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  /* display: flex;
        justify-content: center; */
  text-align: center !important;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 10000;
}

.whatsapp_float:hover {
  color: #fff;
}

.whatsapp-icon {
  margin-top: 16px;
  position: relative;
  top: 14px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .fd-top {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 20px;
    justify-content: space-between;
  }
  .whatsapp-icon {
    margin-top: 10px;
    top: 5px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.footer-links {
  color: #fff;
}

.footer-links:hover {
  color: #daa520;
}

.quote {
  font-size: 25px;
}

.footer-copyright {
  background-color: #417c8b61;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: #fff;
  text-decoration: none;
}

.facebook_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 110px;
  right: 40px;
  background-color: #3b5998;
  color: #fff;
  border-radius: 50px;
  /* display: flex;
        justify-content: center; */
  text-align: center !important;
  padding-top: 15px;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 10000;
}

.instagram_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 180px;
  right: 40px;
  background-color: #e4405f;
  color: #fff;
  border-radius: 50px;
  /* display: flex;
        justify-content: center; */
  text-align: center !important;
  padding-top: 15px;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 10000;
}

.instagram_float:hover {
  color: #fff;
}

.facebook_float:hover {
  color: #fff;
}

.youtube_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 250px;
  right: 40px;
  background-color: #ff0000;
  color: #fff;
  border-radius: 50px;
  /* display: flex;
        justify-content: center; */
  text-align: center !important;
  padding-top: 15px;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 10000;
}

.youtube_float:hover {
  color: #fff;
}
/* for mobile */

@media screen and (max-width: 767px) {
  .instagram-icon {
    margin-top: 10px;
    top: 5px;
  }

  .instagram_float {
    width: 40px;
    height: 40px;
    bottom: 140px;
    right: 10px;
    font-size: 22px;
    padding-top: 5px;
  }

  /* facebook */
  .facebook_float {
    width: 40px;
    height: 40px;
    bottom: 80px;
    right: 10px;
    font-size: 22px;
    padding-top: 10px;
  }

  .facebook-icon {
    margin-top: 10px;
    top: 5px;
  }

  /* youtube */
  .youtube_float {
    width: 40px;
    height: 40px;
    bottom: 200px;
    right: 10px;
    font-size: 22px;
    padding-top: 5px;
  }

  .youtube-icon {
    margin-top: 10px;
    top: 5px;
  }
}
