.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000000;
    /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: relative;
    background-color: white;
    padding: 20px;
    width: 400px;
    border-radius: 8px;
}

.deleteImage {
    cursor: pointer;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 5px;
    right: 8px;
    transform: translate(50%, -50%);

}

@media (max-width: 600px) {
    .modal {
        width: 80%;
    }

}

.modal h5 {
    margin-top: 0;
    font-weight: 500;
    color: #4c4c4d
}

.modal span {
    /* margin-bottom: 20px; */
    font-weight: 600;
    font-size: 18px;
}

.modal button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.modal button:hover {
    background-color: #0056b3;
}